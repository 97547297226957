
@font-face {
  font-family: Doctracker;
  src: local('Doctracker'),
  url('./assets/fonts/ruberoid/Ruberoid-Regular.woff2') format('woff2'),
  url('./assets/fonts/ruberoid/Ruberoid-Regular.woff') format('woff'),
  url('./assets/fonts/ruberoid/Ruberoid-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Ubuntu;
  src: local('Ubuntu'),
  url('./assets/fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  src: local('Roboto'),
  url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Inter;
  src: local('Inter'),
  url('./assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
}

@font-face {
  font-family: NotoSans;
  src: local('NotoSans'),
  url('./assets/fonts/Noto_Sans_HK/NotoSansHK-VariableFont_wght.ttf') format('truetype');
}


body {
  background: rgba(41,45,62,1);
  background-image: linear-gradient(90deg, rgb(18, 22, 32) 0%, rgb(6, 20, 29) 55%, rgb(18, 22, 32) 100%);
  /* 
  background: linear-gradient(90deg, rgba(41,45,62,1) 0%, rgb(26 69 79) 55%, rgba(41,45,62,1) 100%);
 */
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: NotoSans;;
}

:root {
  --main: #3957a6;/* #4671e0; */ /* #395fc0; */ /* #405da8; */ /* #773AC5 */
  --hover: #4671e0;
  --second: #FFB240;
  --v: #2e8555;
  --vhover: #205d3b;
  
  --block: #161c2c; /* #19223c; */ /* #141a2c; */ /*  #1b1f2a; */ /* #252837; */
  --block2: #19223c; /* #22242f; */
  --block3: #212f56;
  --borderBlock: #b58341;


  --alt: #89a4fa;
  --alt2: #f5f451;
  --alt3: #e9f08e;
}

h1 {
  font-family: NotoSans, Charlie Display, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
}

h2 {
  font-weight: 300;
}

h3 {
  font-size: 1.5rem;
}

.head-pd {
  padding-bottom: 30px;
}

p {
  color: rgb(255 255 255 / 87%);
  font-size: 1.15rem;
  line-height: 1.9rem;
  font-family: NotoSans, Charlie Display, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
}

.as-paragraph {
  font-size: 1.15rem;
  color: rgb(255 255 255 / 87%);
  font-weight: 300;
}

@media(max-width: 500px) {
  h1 { font-size: 1.8rem;}
  h3 { font-size: 1.8rem;}
  p { font-size: 1rem;}
  .as-paragraph { font-size: 1rem;}
}

a {
  color: #5591ff;
}
a:hover {
  color: #75a6ff;
}

.white-space-preline {
  white-space: pre-line;
  margin-top: -1.5rem;
}


.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page-center {
  width: 1100px;
}
.page-one {
  min-height: 80vh;
  max-width: 1100px;
}
.page-one + .page-one {
  margin-top: 10rem;
}
.page-half {
  min-height: 60vh;
}
.page-half2 {
  min-height: 40vh;
}
.page-alt-bg {
  background-color: var(--block); /* #252837; */ /* #222; */
}

.page-mg-top {
  margin-top: 30vh;
}
.page-pd {
  padding: 10vh 0;
}
.page-one:nth-child(even) {
/* 
  background-color: #1b1c1e;
 */
}

.rows {
  display: flex;
}
/* 
.text-block {
  border-left: 1px solid var(--second);
}
 */
.text-padding {
  padding-left: 2rem;
  padding-right: 1rem;
}
.text-padding-min {
  padding-left: 1rem;
  padding-right: 1rem;
}
/* 
.text-block p + h3 {
  margin-top: 4rem;
  margin-bottom: 1rem;
}
 */
.text-center {
  text-align: center;
}

.pc-image {
/*   background-color: var(--block); */
  padding: 2rem;
  max-width: 100%;
  text-align: center;
}
@media(max-width: 1100px) {
  .pc-image {
    padding: 1.5rem;
  }
}


.pc-image img {
  border-radius: 10px;
  box-shadow: 0 0 15px 0px #0f0f0f;
  max-width: 100%;
}

.gradient {
  background: rgba(41,45,62,1);
  background: radial-gradient(circle, rgba(41,45,62,1) 10%, rgba(41,56,62,1) 100%);
  /* 
  background: -webkit-linear-gradient(90deg,#395492,#0099c0,#3dd5a8);  background: linear-gradient(90deg,#395492,#0099c0,#3dd5a8);
  background: linear-gradient(135deg, hsla(0, 0%, 0%, 1) 0%, hsla(222, 44%, 40%, 1) 32%, hsla(192, 100%, 38%, 1) 53%, hsla(162, 64%, 54%, 1) 71%, hsla(0, 0%, 1%, 1) 100%);
  box-shadow: 0 0 60px 65px inset #171717, 0 0 15px 10px inset #171717;
   */
  /* 
  background: linear-gradient(135deg, #000 0%, #395793 100%);
   */
/* 
   background:linear-gradient(135deg, #000 0%, #254e91 100%);
   padding-bottom: 1rem;
 */
   /* 
  position: absolute;
  height: 400px;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
   */
}

.focus-bg {
  background: linear-gradient(315deg, #1d294c 0%, var(--block3) 100%);
}

.block-focus {
  background-color: var(--block3);
  background: linear-gradient(315deg, #1d294c 0%, var(--block3) 100%);
  padding: 10px 30px;
  border-radius: 10px;
}


.next-screen {
  margin-top: 50vh;
}

.text-center {
  text-align: center;
}

.video-content {
  margin: 50px 0;
}

#video-tutorial {
  width: 1280px;
  height: 720px;
}
@media(max-width: 1280px) {
    #video-tutorial {
    width: 560px;
    height: 315px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background-color: var(--block);
}

.footer .contacts {
}

.contacts {
  display: flex;
  flex-direction: row;
}

@media(max-width: 1100px) {
  .contacts {
    flex-direction: column;
  }
}

.footer .contact-item {
  display: flex;
  align-items: center;
  margin: 10px;
}

.header {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  padding: 10px;
}



@media(max-width: 1100px) {
  .header {
    width: 100%;
    background-color: var(--block2);
    justify-content: flex-end;
    display: flex;
  }
}

.header .sign-in {
  margin-right: 100px;
}
@media(max-width: 1100px) {
  .header .sign-in {
    margin-right: 0;
  }
}


.header .sign-in a {
  display: block;
  font-size: 1.25rem;
  background-color: var(--main);
  color: #fff;
  padding: 10px 20px;
  font-family: inherit;
  font-size: 1.1rem;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  width: fit-content;
}


.header .sign-in a:hover {
  cursor: pointer;
  background-color: var(--hover);
}

.header .sign-in p {
  font-size: 0.9rem;
  color: #78ff78;
}



.copyright {
  padding: 20px;
  text-align: center;
  font-size: 0.8rem;
  max-height: 70px;
  overflow: auto;
}

#lift {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 30px;
  background-color: rgb(45 50 55 / 40%);
  opacity: 0;
}


html {
  scrollbar-face-color: #646464;
  scrollbar-base-color: #646464;
  scrollbar-3dlight-color: #646464;
  scrollbar-highlight-color: #646464;
  scrollbar-track-color: #000;
  scrollbar-arrow-color: #000;
  scrollbar-shadow-color: #646464;
}

::-webkit-scrollbar { width: 8px; }
::-webkit-scrollbar-track {  background-color: rgba(0,0,0,0);}
::-webkit-scrollbar-track-piece { background-color: rgba(0,0,0,0)}
::-webkit-scrollbar-thumb { background-color: #666}
::-webkit-scrollbar-button {  background-color: #666; }


.draft {
  display: none;
}

.blog {
  max-width: 800px;
}